import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_solutions.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p className="leading-relaxed text-lg">
  We optimize, upload and maintain your content if you have no time to do it.
  You don't have great pictures, professional text and GPS data for your
  activities? We are experts in creating high quality content to show what makes
  your destination unique.
    </p>
    <br />
    <br />
    <h4>{`Content management`}</h4>
    <p>{`We can do the work for you!`}</p>
    <br />
    <p>{`Trailguide has been created to be easy to use, but if you find you do not have
the capacity to work with the content yourself, we can do it for you. We will
upload and maintain your content for you.`}</p>
    <br />
    <p>{`We optimize your outdoor content preparing it so your map is clear and easy to
use, the descriptions are helpful and to the point, and making sure your
pictures are optimized to look great.`}</p>
    <br />
    <p>{`We also create the customized maps and plugins you need for your website,
print-products and QR codes for outdoor signs.`}</p>
    <br />
    <Image src="solutions/savetimeblack.png" className="my-12 w-1/2 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`Creating content`}</h4>
    <p>{`If you currenty lack great pictures, professional text and GPS data for your
activities or need updated content, we have the expertice and experience to
create high quality content showing what makes your destination unique.`}</p>
    <br />
    <p>{`Content is the basic element of the communication to your potential guests.
Often decisions are made in a split of a second and the first impression
counts. Benefit from our long experience in outdoor sports and media creation
to represent your product in an attractive and professional way.`}</p>
    <br />
    <p>{`The usage of this content is not limited to Trailguide, but can be used in all
your communication channels!`}</p>
    <Image src="solutions/flowtrailstmoritz.png" className="my-12 w-full mx-auto" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      